import type { CSSProperties, VideoHTMLAttributes } from "vue"
import { ImageOption } from "~~/types/components/common/animationGallery"
import { TransformParam } from "~~/types/components/common/scrollBlock"
import { FeatureIntroductionProps } from "~~/types/components/pages/home/featureIntroduction"
import { getCloudAssets } from "~~/utils"
import { sizeEnum } from "../breakpoint"
import homeLocale from "~~/lang/en/home.json"
import { useTranslateI18n } from "~~/composables/store/i18n-country"
import {
  PORTRAIT_RETOUCHING,
  COLOR_AND_TONE,
  BACKGROUND_ADJUSTMENTS,
  EXCLUSIVE_PRESETS,
  BATCH_EDITS
} from "@/constant/route"

// 依次显现动画模块
type S = Record<sizeEnum, CSSProperties>
type Gallery = Omit<ImageOption, "style"> & { style?: S }

const WORKSPACE_S_ORG_W = 1000
const WORKSPACE_S_ORG_H = 545.5

const WORKSPACE_S_P1_W = 151
const WORKSPACE_S_P1_H = 237

const WORKSPACE_S_P2_W = 158
const WORKSPACE_S_P2_H = 199

const WORKSPACE_S_P3_W = 157
const WORKSPACE_S_P3_H = 80

export const gallery: Gallery[] = [
  {
    type: "normal",
    src: getCloudAssets("/images/pages/home/workspace_img 2.webp"),
    style: {
      [sizeEnum.XS]: {},
      [sizeEnum.SM]: {
        width: `100%`,
        height: `100%`
      },
      [sizeEnum.MD]: {
        width: `${WORKSPACE_S_ORG_W * 0.6}px`,
        height: `${WORKSPACE_S_ORG_H * 0.6}px`
      },
      [sizeEnum.LG]: {
        width: `${WORKSPACE_S_ORG_W * 0.85}px`,
        height: `${WORKSPACE_S_ORG_H * 0.85}px`
      },
      [sizeEnum.XL]: {
        width: `${WORKSPACE_S_ORG_W}px`,
        height: `${WORKSPACE_S_ORG_H}px`
      }
    }
  },
  {
    type: "transition",
    transitionName: "slide-fade-left-delay",
    src: getCloudAssets("/images/pages/home/workspace_part1.png"),
    style: {
      [sizeEnum.XS]: {},
      [sizeEnum.SM]: {},
      [sizeEnum.MD]: {
        position: "absolute",
        top: "18.7%", // 92
        left: "0",
        width: `${WORKSPACE_S_P1_W * 0.6}px`,
        height: `${WORKSPACE_S_P1_H * 0.6}px`
      },
      [sizeEnum.LG]: {
        position: "absolute",
        top: "18.7%", // 92
        left: "0",
        width: `${WORKSPACE_S_P1_W * 0.85}px`,
        height: `${WORKSPACE_S_P1_H * 0.85}px`
      },
      [sizeEnum.XL]: {
        position: "absolute",
        top: "18.7%", // 92
        left: "0",
        width: `${WORKSPACE_S_P1_W}px`,
        height: `${WORKSPACE_S_P1_H}px`
      }
    }
  },
  {
    type: "transition",
    transitionName: "slide-fade-bottom-delay",
    src: getCloudAssets("/images/pages/home/workspace_part2.png"),
    style: {
      [sizeEnum.XS]: {},
      [sizeEnum.SM]: {},
      [sizeEnum.MD]: {
        position: "absolute",
        bottom: "0",
        right: "18%", // 165
        width: `${WORKSPACE_S_P2_W * 0.6}px`,
        height: `${WORKSPACE_S_P2_H * 0.6}px`
      },
      [sizeEnum.LG]: {
        position: "absolute",
        bottom: "0",
        right: "18%", // 165
        width: `${WORKSPACE_S_P2_W * 0.85}px`,
        height: `${WORKSPACE_S_P2_H * 0.85}px`
      },
      [sizeEnum.XL]: {
        position: "absolute",
        bottom: "0",
        right: "18%", // 165
        width: `${WORKSPACE_S_P2_W}px`,
        height: `${WORKSPACE_S_P2_H}px`
      }
    }
  },
  {
    type: "transition",
    transitionName: "slide-fade-right-delay",
    src: getCloudAssets("/images/pages/home/workspace_part3.png"),
    style: {
      [sizeEnum.XS]: {},
      [sizeEnum.SM]: {},
      [sizeEnum.MD]: {
        position: "absolute",
        top: "28%",
        right: "0",
        width: `${WORKSPACE_S_P3_W * 0.6}px`,
        height: `${WORKSPACE_S_P3_H * 0.6}px`
      },
      [sizeEnum.LG]: {
        position: "absolute",
        top: "28%",
        right: "0",
        width: `${WORKSPACE_S_P3_W * 0.85}px`,
        height: `${WORKSPACE_S_P3_H * 0.85}px`
      },
      [sizeEnum.XL]: {
        position: "absolute",
        top: "28%",
        right: "0",
        width: `${WORKSPACE_S_P3_W}px`,
        height: `${WORKSPACE_S_P3_H}px`
      }
    }
  }
]

export function getGalleryByBreakpoint(pageSize: sizeEnum): ImageOption[] {
  return gallery.map((img) => {
    return {
      ...img,
      style: img.style[pageSize]
    }
  })
}

// 滚动动画块模块
export type PositionParam = { origin?: TransformParam; offset?: TransformParam; maxScrollY?: number }
type ScrollBlock = Record<sizeEnum, PositionParam>
const scrollBlockParams: ScrollBlock = {
  [sizeEnum.XS]: {},
  [sizeEnum.SM]: {},
  [sizeEnum.MD]: {
    origin: {
      x: 20,
      y: 75,
      scaleX: 0.85,
      scaleY: 0.85
    },
    offset: {
      x: 20,
      y: 75,
      scaleX: 0.2,
      scaleY: 0.2
    },
    maxScrollY: 0.75 * WORKSPACE_S_ORG_H
  },
  [sizeEnum.LG]: {
    origin: {
      x: 25,
      y: 56,
      scaleX: 0.85,
      scaleY: 0.85
    },
    offset: {
      x: 25,
      y: 56,
      scaleX: 0,
      scaleY: 0
    },
    maxScrollY: 800
  },
  [sizeEnum.XL]: {
    origin: {
      x: 34,
      y: 41.5,
      scaleX: 1,
      scaleY: 1
    },
    offset: {
      x: 34,
      y: 41.5,
      scaleX: 0.1,
      scaleY: 0.1
    },
    maxScrollY: 800
  }
}

export function getScrollBlockPositionByBp(pageSize: sizeEnum): PositionParam {
  return scrollBlockParams[pageSize]
}

// 特性介绍模块
export const featureIntroductionList = () => [
  {
    path: unref(PORTRAIT_RETOUCHING),
    videoUrl: getCloudAssets("/videos/pages/home/portrait-beautification.mp4"),
    poster: getCloudAssets("/images/pages/home/home_video_poste04%403x.webp")
  },
  {
    path: unref(COLOR_AND_TONE),
    reserve: true,
    videoUrl: getCloudAssets("/videos/pages/home/color-and-tone.mp4"),
    poster: getCloudAssets("/images/pages/home/home_video_poste05%403x.webp")
  },
  {
    path: unref(BACKGROUND_ADJUSTMENTS),
    videoUrl: getCloudAssets("/videos/pages/home/image-editing.mp4"),
    poster: getCloudAssets("/images/pages/home/home_video_poste06%403x.webp")
  },
  {
    path: unref(EXCLUSIVE_PRESETS),
    reserve: true,
    videoUrl: getCloudAssets("/videos/pages/home/exclusive-presets.mp4"),
    poster: getCloudAssets("/images/pages/home/home_video_poste07%403x.webp")
  },
  {
    path: unref(BATCH_EDITS),
    listStyle: "decimal",
    videoUrl: getCloudAssets("/videos/pages/home/batch-editing2.mp4"),
    poster: getCloudAssets("/images/pages/home/home_video_poste08%403x.webp")
  }
]

// // 特性模块点击more point dict
// export const featureIntroductionListDict = {
//   [featureIntroductionList[0].path]: "portraitMore",
//   [featureIntroductionList[1].path]: "colorMore",
//   [featureIntroductionList[2].path]: "backgroundMore",
//   [featureIntroductionList[3].path]: "presetMore",
//   [featureIntroductionList[4].path]: "batchMore"
// }

export function isJSON(str: string) {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}
type ListItem = Pick<FeatureIntroductionProps, "path" | "videoUrl" | "listStyle" | "reserve">
export function getFeatureIntroductionList(
  list: ListItem[],
  localePath = homeLocale.home.featureIntroduction,
  localePathKey = "home.featureIntroduction",
  showMoreBtn = true
): FeatureIntroductionProps[] {
  return list.map((item, i) => {
    return {
      ...item,
      title: useTranslateI18n(`${localePathKey}[${i}].title`),
      list:
        localePath[i].list && Array.isArray(localePath[i].list)
          ? localePath[i].list?.map((text, index) => useTranslateI18n(`${localePathKey}[${i}].list[${index}]`))
          : "",
      description: useTranslateI18n(`${localePathKey}[${i}].description`),
      showMoreBtn
    }
  })
}

type ProductChapterItem = {
  title: string[]
  describe: string[]
  videoUrl: string
  poster?: VideoHTMLAttributes["poster"]
}
type ProductChapterItemPosition = {
  paddingTop?: number
  paddingBottom?: number
  offset?: number
}
type ProductChapterItemParams = Record<sizeEnum, ProductChapterItemPosition>
export const productChapter: Pick<ProductChapterItem, "videoUrl" | "poster">[] = [
  {
    // title: ["All-in-One", "Workflow"],
    // describe: [
    //   "No need to switch between multiple software.",
    //   "Combine all your photo editing work in one <span class='bg'>single</span>, <span class='bg'>powerful</span> app."
    // ],
    videoUrl: getCloudAssets("/videos/pages/home/workflow.mp4"),
    poster: getCloudAssets("/images/pages/home/home_video_poste01%403x.webp")
  },
  {
    // title: ["Streamline Your Work"],
    // describe: [
    //   "No more repetitive work. <span class='bg'>Sync</span> your edits with Evoto <span class='bg'>in seconds</span>."
    // ],
    videoUrl: getCloudAssets("/videos/pages/home/batch-editing.mp4"),
    poster: getCloudAssets("/images/pages/home/home_video_poste02%403x.webp")
  },
  {
    // title: ["Leading Edge Effects"],
    // describe: ["Adding <span class='bg'>cutting-edge effects</span> has never been so easy."],
    videoUrl: getCloudAssets("/videos/pages/home/presets.mp4"),
    poster: getCloudAssets("/images/pages/home/home_video_poste03%403x.webp")
  }
]

const productChapterParams: ProductChapterItemParams = {
  [sizeEnum.XS]: {},
  [sizeEnum.SM]: {},
  [sizeEnum.MD]: {
    paddingTop: 400,
    paddingBottom: 200,
    offset: 15
  },
  [sizeEnum.LG]: {
    paddingTop: 400,
    paddingBottom: 300,
    // offset: 400
    offset: 15
  },
  [sizeEnum.XL]: {
    paddingTop: 400,
    paddingBottom: 300,
    // offset: 200
    offset: 15
  }
}
export function getProductChapterParams(pageSize: sizeEnum) {
  return productChapterParams[pageSize]
}

export function getProductChapterItemByBp(pageSize: sizeEnum): Array<ProductChapterItem & ProductChapterItemPosition> {
  return productChapter.map((item, i) => {
    return {
      ...localesHelper(i, item),
      ...getProductChapterParams(pageSize)
    }
  })
}

function localesHelper(i: number, item: Pick<ProductChapterItem, "videoUrl">): ProductChapterItem {
  return {
    ...item,
    title: homeLocale.home.productChapter[i].title.map((t, index) =>
      useTranslateI18n(`home.productChapter[${i}].title[${index}]`)
    ),
    describe: homeLocale.home.productChapter[i].describe.map((t, index) =>
      useTranslateI18n(`home.productChapter[${i}].describe[${index}]`, [
        {
          type: "static",
          class: "bg"
        },
        {
          type: "static",
          class: "bg"
        }
      ])
    )
  }
}

export const HERO_ID = "hero_id"

export const HERO_FREE_BTN = "hero_free_btn"
